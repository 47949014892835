import styled from 'styled-components/macro';

export const FooterContainer = styled.footer`
  background-color: #000;
`;

export const FooterInner = styled.div`
  padding: 2rem 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 540px) {
    flex-direction: column;
  }

  p {
    font-size: 0.75rem;
    color: #808080;

    @media screen and (max-width: 540px) {
      margin: 0.5rem;
    }
  }
`;

export const FooterLogo = styled.div`
  display: flex;
  align-items: center;

  h5 {
    color: #fff;
    margin-left: 0.5rem;
  }
`;

export const Social = styled.ul`
  display: flex;
  align-items: center;

  li {
    margin: 0 0.5rem;

    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      opacity: 50%;
    }
  }
`;
