import React from 'react';
import {Link} from 'react-scroll';
import styled, {css} from 'styled-components/macro';
import {Loader} from './Loader';

export function LinkButton({children, ...restProps}) {
  return <LinkButtonContainer {...restProps}>{children}</LinkButtonContainer>;
}

export function SubmitButton({children, isLoad, ...restProps}) {
  return (
    <SubmitButtonContainer {...restProps}>
      {isLoad ? <Loader /> : children}
    </SubmitButtonContainer>
  );
}

const LinkButtonContainer = styled(Link)`
  font-size: 1rem;
  font-weight: 700;
  color: #000;
  text-align: center;

  background-color: #fff;
  border: 2px solid #fff;

  cursor: pointer;

  padding: 1rem 2rem;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: #fff;
    background-color: #ffffff14;
  }

  ${({secondary}) =>
    secondary &&
    css`
      color: #000;
      background-color: #fff;

      &:hover {
        color: #000;
        background-color: #00000014;
      }
    `}
`;

export const SubmitButtonContainer = styled.button`
  font-size: 1rem;
  font-weight: 700;
  color: #000;

  min-width: 150px;
  background-color: #fff;
  border: 2px solid #fff;

  padding: 1rem 2rem;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: #fff;
    background-color: #ffffff29;
  }

  &:disabled {
    background-color: transparent;
  }
`;
