import React, {useState} from 'react';
import {useScroll} from '../../hooks/useScroll';
import {sectionAnim} from '../../animations';
import {MapContainer, Marker, TileLayer} from 'react-leaflet';
import 'mapbox-gl/dist/mapbox-gl.css';
import {CommonWrapper} from '../common/Layout';
import {Title} from '../common/Title';
import {SubmitButton} from '../common/Button';
import {Popup} from '../Popup';
import {
  ContactsSection,
  ContactsContent,
  ContactsInfoCol,
  Form,
  FormRow,
  ContactsInfoRow,
  StatusMessage,
} from './styles/Contacts';
import ReactGA from 'react-ga';

export default function Contacts() {
  const [element, controls] = useScroll();
  const [email, setEmail] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
  });
  const [isSent, setIsSent] = useState(false);
  const [status, setStatus] = useState(null);
  const [isPopup, setIsPopup] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setEmail({
      ...email,
      [event.target.name]: value,
    });
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setIsLoad(true);
    fetch('/mail.php', {
      method: 'POST',
      body: JSON.stringify(email),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      if (response.status === 200) {
        setIsLoad(false);
        setIsPopup(true);
        setTimeout(() => {
          setIsPopup(false);
        }, 3000);
        setIsSent(true);
        setStatus('Submitted successfully.');
        ReactGA.event({
        category: 'Contacts',
        action: 'Contact has been sent'
});
      } else {
        setIsSent(false);
        setStatus('The message has not been sent. Please try again.');
      }
    }).catch((e) => {
      console.log(e)
    });
  };

  return (
    <ContactsSection
      id="contacts"
      variants={sectionAnim}
      animate={controls}
      initial="hidden"
      ref={element}
    >
      {isPopup ? <Popup /> : null}
      <CommonWrapper>
        <Title light>Contacts</Title>
        <ContactsContent>
          <ContactsInfoRow>
            <ContactsInfoCol>
              <p>
                303 5th avenue, Ste. 906 <br /> New York, NY, 10016
              </p>
            </ContactsInfoCol>
            <ContactsInfoCol>
              <a href="tel:646-708-2338"><p>Phone: (646) 708-2338</p></a>
              <a href="mailto:befree@santelaser.com">Email: befree@santelaser.com</a>
            </ContactsInfoCol>
          </ContactsInfoRow>
          <Form onSubmit={onSubmit}>
            <FormRow col={2}>
              <input
                name="firstName"
                type="text"
                placeholder="First Name *"
                required
                onChange={handleInputChange}
              />
              <input
                name="lastName"
                type="text"
                placeholder="Last Name *"
                required
                onChange={handleInputChange}
              />
            </FormRow>
            <FormRow col={2}>
              <input
                name="email"
                type="email"
                placeholder="Email *"
                required
                onChange={handleInputChange}
              />
              <input
                name="phone"
                type="number"
                placeholder="Phone *"
                required
                onChange={handleInputChange}
              />
            </FormRow>
            <FormRow col={1}>
              <input
                name="message"
                type="text"
                placeholder="Message"
                onChange={handleInputChange}
              />
            </FormRow>
            <FormRow col={1} align={'end'}>
              <div>
                {status ? (
                  <StatusMessage error={isSent ? false : true}>
                    {status}
                  </StatusMessage>
                ) : null}
                <SubmitButton
                  type="submit"
                  isLoad={isLoad}
                  disabled={isLoad ? true : false}
                >
                  Contact Us
                </SubmitButton>
              </div>
            </FormRow>
          </Form>
        </ContactsContent>
      </CommonWrapper>
      <MapContainer
        center={[40.74663036909428, -73.9853678812425]}
        zoom={13}
        scrollWheelZoom={false}
        style={{height: '300px', zIndex: 1}}
      >
        <TileLayer
          attribution=""
          url="https://api.mapbox.com/styles/v1/dimkry/ckh3z0rr108l619pdmet29gac/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZGlta3J5IiwiYSI6ImNraDN5eDl2YTAxYXIyemswOWY1aXJobHYifQ.M5uk-wDMQX7sq_YlbH6DBQ"
        />
        <Marker position={[40.74663036909428, -73.9853678812425]} />
      </MapContainer>
    </ContactsSection>
  );
}
