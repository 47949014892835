import {createGlobalStyle} from 'styled-components';

const GlobalStyles = createGlobalStyle`
body {
  box-sizing: border-box;

  font-family: 'Encode Sans', sans-serif;
  font-size: 16px;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 3.5rem;
  font-weight: 700;

   @media screen and (max-width: 414px) {
      font-size: 2rem;
    }
}

h2 {
  font-size: 3rem;

  @media screen and (max-width: 414px) {
    font-size: 2rem;
  }
}

h3 {
   font-size: 2.5rem;
}

h4 {
  font-size: 1.5rem;
  font-weight: 600;
}

h5 {
  font-size: 1rem;
  font-weight: 600;
}

p {
  font-size: 1rem;
  line-height: 1.75rem;
}

a {
  font-size: 1rem;
  line-height: 1.75rem;
  text-decoration: none;
  cursor: pointer;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

button {
  background-color: transparent;
  border-style: none;
  cursor: pointer;
}

input {
  height: 3rem;

  padding-left: 1rem;

  border-style: none;
  border-bottom: 2px solid #fff;
  
  background: transparent;

  font-size: 1rem;
  color: #fff;

  &::placeholder {
      font-size: 1rem;
      color: #fff;
  }
}
`;

export default GlobalStyles;
