import styled from 'styled-components/macro';

export const ReviewsContent = styled.div`
  max-width: 600px;

  margin: 3rem auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  position: relative;

  img {
    margin-bottom: 2rem;
  }
`;

export const Slider = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  .review-container {
    width: 100%;
    height: 200px;

    position: relative;

    .review {
      position: absolute;

      opacity: 0;

      transition: all 0.3s ease-in-out;

      margin-bottom: 1rem;

      text-align: center;

      @media screen and (max-width: 414px) {
        height: 200px;
      }

      &--active {
        opacity: 1;
      }
    }
  }

  .names-container {
    display: flex;

    @media screen and (max-width: 414px) {
      align-items: center;
    }

    li {
      margin: 0 3rem;

      cursor: pointer;

      @media screen and (max-width: 414px) {
        margin: 0 1.5rem;
      }

      .name {
        font-weight: 400;

        &--active {
          font-weight: 700;
        }
      }
    }
  }
`;
