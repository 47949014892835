import React from 'react';
import {useScroll} from '../../hooks/useScroll';
import {sectionAnim} from '../../animations';
import {CommonSection, CommonWrapper} from '../common/Layout';
import {Title} from '../common/Title';
import {Cards, Card} from './styles/Pricing';
import {LinkButton} from '../common/Button';

export default function Pricing({pricing}) {
  const [element, controls] = useScroll();
  return (
    <CommonSection
      id="pricing"
      variants={sectionAnim}
      animate={controls}
      initial="hidden"
      ref={element}
    >
      <CommonWrapper>
        <Title>Pricing</Title>
        <Cards>
          {pricing.map(({title, info, additionalInfo, totalPrice, price}) => (
            <Card key={title}>
              <div>
                <h4>{title}</h4>
                <h5>Package of 6</h5>
                <h3>{totalPrice}</h3>
                <h5>Single session</h5>
                <p className="additional-info">
                  {additionalInfo}
                  <span>{price}</span>
                </p>
                <p className="additional-info">{info}</p>
              </div>
              <LinkButton
                secondary={1}
                to="contacts"
                smooth={true}
                duration={500}
                spy={true}
                offset={-80}
              >
                Book Now
              </LinkButton>
            </Card>
          ))}
        </Cards>
      </CommonWrapper>
    </CommonSection>
  );
}
