import React from 'react';
import {useScroll} from '../../hooks/useScroll';
import {sectionAnim} from '../../animations';
import {TechSection, TechItem, TechContent, TechImage} from './styles/Tech';
import {Title} from '../common/Title';
import {CommonWrapper} from '../common/Layout';

export default function Tech({tech}) {
  return (
    <TechSection id="whyus">
      <CommonWrapper>
        {tech.map((item, index) => (
          <Item key={index} item={item} />
        ))}
      </CommonWrapper>
    </TechSection>
  );
}

function Item(props) {
  const [element, controls] = useScroll();
  const {title, text, imgStart, additionalInfo, price, img, webp} = props.item;
  return (
    <TechItem
      imgStart={imgStart}
      key={title}
      variants={sectionAnim}
      animate={controls}
      initial="hidden"
      ref={element}
    >
      <TechContent imgStart={imgStart}>
        <Title>{title}</Title>
        <p>{text}</p>
        {additionalInfo && price ? (
          <div className="additional-info">
            <p>
              {additionalInfo}
              <span>{price}</span>
            </p>
          </div>
        ) : null}
      </TechContent>
      <TechImage>
        <picture>
          <source srcSet={webp} type="image/webp" />
          <source srcSet={img} type="image/jpeg" />
          <img src={img} alt={title} />
        </picture>
      </TechImage>
    </TechItem>
  );
}
