import React from 'react';
import {motion} from 'framer-motion';
import {HeroSection, HeroContent} from './styles/Hero';
import main_logo from '../../assets/icons/ic_logo_main.svg';
import {CommonWrapper} from '../common/Layout';
import {LinkButton} from '../common/Button';
import {titleAnim, logoAnim, bookContainerAnim} from '../../animations';

export default function Hero() {
  return (
    <HeroSection id="hero">
      <CommonWrapper>
        <HeroContent>
          <motion.img variants={logoAnim} src={main_logo} alt="sante-laser" />
          <motion.h1 variants={titleAnim}>Set your body free</motion.h1>
          <motion.div
            variants={bookContainerAnim}
            className="book-now-container"
          >
            <h5>Book your consultation!</h5>
            <LinkButton
              secondary={0}
              to="contacts"
              smooth={true}
              duration={500}
              spy={true}
              offset={-80}
            >
              Book Now
            </LinkButton>
          </motion.div>
        </HeroContent>
      </CommonWrapper>
    </HeroSection>
  );
}
