import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: #fff;
`;

export const Content = styled.div`
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;

  h4 {
    margin-bottom: 1.5rem;
  }
`;
