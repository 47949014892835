import styled from 'styled-components/macro';

export const Title = styled.h2`
  color: ${({light}) => (light ? '#fff' : '#000')};

  &::after {
    content: '';
    display: block;
    width: 120px;
    height: 4px;
    background-color: ${({light}) => (light ? '#fff' : '#000')};
    margin: 24px 0;
  }
`;
