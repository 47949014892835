import styled, {css} from 'styled-components/macro';
import {motion} from 'framer-motion';

export const ContactsSection = styled(motion.section)`
  padding: 3.75rem 0 0 0;
  background-color: #000;
`;

export const ContactsContent = styled.div`
  color: #fff;
`;

export const ContactsInfoRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 5rem;

  @media screen and (max-width: 414px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ContactsInfoCol = styled.div`
  margin-bottom: 1rem;
  a {
 color: #fff;
  }
 
`;

export const Form = styled.form`
  margin-bottom: 2rem;
`;

export const FormRow = styled.div`
  display: grid;
  grid-template-columns: repeat(${({col}) => col || 1}, 1fr);
  column-gap: 5rem;
  justify-items: ${({align}) => align || 'stretch'};
  align-items: center;
  margin-bottom: 1rem;

  div {
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 414px) {
    grid-template-columns: repeat(1, 1fr);
  }

  &:last-child {
    padding-top: 1.5rem;
    @media screen and (max-width: 414px) {
      margin-bottom: 0;
    }
  }
`;

export const StatusMessage = styled.div`
  margin-right: 1.5rem;
  padding: 1rem 2rem;

  color: #7ed321;

  background-color: #7ed32129;
  border: 1px solid #7ed321;

  ${({error}) =>
    error &&
    css`
      color: #ff3131;

      background-color: #ff313129;
      border: 1px solid #ff3131;
    `}
`;
