import img_tech_1 from './assets/images/tech_1.jpg';
import img_tech_1_webp from './assets/images/tech_1.webp';
import img_tech_2 from './assets/images/tech_2.jpg';
import img_tech_2_webp from './assets/images/tech_2.webp';
import img_tech_3 from './assets/images/tech_3.jpg';
import img_tech_3_webp from './assets/images/tech_3.webp';
import img_tech_4 from './assets/images/tech_4.jpg';
import img_tech_4_webp from './assets/images/tech_4.webp';
import img_tech_5 from './assets/images/tech_5.jpg';
import img_tech_5_webp from './assets/images/tech_5.webp';
import img_tech_6 from './assets/images/tech_6.jpg';
import img_tech_6_webp from './assets/images/tech_6.webp';
import img_tech_7 from './assets/images/tech_7.jpg';
import img_tech_7_webp from './assets/images/tech_7.webp';
import img_tech_8 from './assets/images/tech_8.jpg';
import img_tech_8_webp from './assets/images/tech_8.webp';
import img_faq_1 from './assets/images/img_faq_1.jpg';
import img_faq_1_webp from './assets/images/img_faq_1.webp';
import img_faq_2 from './assets/images/img_faq_2.jpg';
import img_faq_2_webp from './assets/images/img_faq_2.webp';
import img_faq_3 from './assets/images/img_faq_3.jpg';
import img_faq_3_webp from './assets/images/img_faq_3.webp';
import img_faq_4 from './assets/images/img_faq_4.jpg';
import img_faq_4_webp from './assets/images/img_faq_4.webp';
import img_faq_5 from './assets/images/img_faq_5.jpg';
import img_faq_5_webp from './assets/images/img_faq_5.webp';
import img_faq_6 from './assets/images/img_faq_6.jpg';
import img_faq_6_webp from './assets/images/img_faq_6.webp';

export const techData = [
  {
    title: 'Advanced Technology That is Safe and Effective',
    text: 'Santé Laser uses the FDA approved Gentle Max Pro made by Syneron - Candela, a dual wavelength laser technology that produces both 755nm alexandrite and 1064nm Nd:YAG energies. This powerful dual capability allows us to provide permanent hair reduction for all skin tones (fair, tan, and dark). The system incorporates a patented dynamic cooling device (DCD™) that protects the epidermis by applying cooling cryogen before and after every laser pulse and helps to reduce the risk of scars and pigmentation.',
    additionalInfo: null,
    price: null,
    imgStart: false,
    img: img_tech_1,
    webp: img_tech_1_webp,
  },
  {
    title: 'Personalized Customer Service Based on Your Unique Needs',
    text: 'Santé Laser aims to provide personalized service to each of our clients at competitive pricing. We have experienced NYS licensed estheticians and Laser professionals on site to provide consultation and treatment options. Everyone has unique needs when it comes to hair removal and we provide customized solutions in our complementary consultation, which also includes a test patch.',
    additionalInfo: null,
    price: null,
    imgStart: true,
    img: img_tech_2,
    webp: img_tech_2_webp,
  },
  {
    title: 'Exceptional Results for Hair Reduction and Ingrown Hairs',
    text: 'The Gentle Max Pro is the Gold Standard for laser hair removal procedures for all skin types. Nd:YAG laser was introduced into the market in the early 90s and since then, the technology has evolved into a powerful reliable system for hair reduction. At Santé we treat all skin tones, providing exceptional results at competitive pricing.',
    additionalInfo: null,
    price: null,
    imgStart: false,
    img: img_tech_3,
    webp: img_tech_3_webp,
  },
  {
    title: 'Microneedling (collagen induction therapy)',
    text: 'Candela’s Exceed microneedling system is FDA cleared, minimally invasive and clinically proven to rejuvenate the skin, reducing the appearance of facial wrinkles and facial acne scars. This treatment prompts a natural, collagen-stimulating healing process which improves quality of the skin and corrects flaws with minimal downtime.',
    additionalInfo: '90 mins | ',
    price: '$349',
    imgStart: true,
    img: img_tech_8,
    webp: img_tech_8_webp,
  },
  {
    title: 'Laser Genesis',
    text: 'This procedure is an exceptional new way to get rid of wrinkles and tighten skin. It promotes collagen growth in the deepest layers of the skin by using deep penetrating wavelengths of laser light, making skin firmer and smoother. This long-lasting effect continues to improve over time.',
    additionalInfo: '30 mins | ',
    price: '$259',
    imgStart: false,
    img: img_tech_5,
    webp: img_tech_5_webp,
  },
  {
    title: 'Vascular Laser Treatment',
    text: 'We offer a noninvasive solution for treating different types of vascular lesions (cherry angiomas, hemangiomas, telangectasias, spider veins, etc.) Vascular lesions form as a result of increased venous pressure and one of the most effective ways to treat them is with the Nd : YAG laser. The laser’s wavelengths penetrate deep into the skin and target blood vessels, leading to heating, micro-clotting, and the eventual collapse of the vessel wall. Most individuals see their vascular lesions disappear after their first treatment.',
    additionalInfo: 'From ',
    price: '$149',
    imgStart: true,
    img: img_tech_6,
    webp: img_tech_6_webp,
  },
  {
    title: 'Chemical Peel',
    text: 'Chemical Peel by PCA Skin is a new exfoliation that is less harsh than traditional chemical peels. This progressive treatment requires minimal down time and is suitable for sensitive skin. It makes skin look youthful and healthy by removing blemishes and treats rosacea and melasma. We offer both facial and body chemical peels.',
    additionalInfo: '30 mins | ',
    price: '$249',
    imgStart: false,
    img: img_tech_7,
    webp: img_tech_7_webp,
  },
  {
    title: 'Pigmented Lesions Removal',
    text: 'Santé offers treatments for excess pigmentation (freckles, brown, age, liver and sun spots). The laser produces a high-powered beam of light energy that hits your skin and fractures the melanin into tiny pieces that are carried away by your immune system or peeled off from the skin. As a result, pigment is removed.',
    additionalInfo: 'From ',
    price: '$149',
    imgStart: true,
    img: img_tech_4,
    webp: img_tech_4_webp,
  },
];

export const faqData = [
  {
    question: 'What is laser hair removal?',
    answer:
      'Laser is a beam of light that targets and heats your hair follicles so they can no longer produce hair. The light emitted by the laser is absorbed by the skin and converted into heat. The surrounding skin is unaffected while the hair follicles become disabled.',
    img: img_faq_1,
    webp: img_faq_1_webp,
    id: 0,
  },
  {
    question: 'Does it hurt?',
    answer:
      'Since laser is a ray of intense light, you may experience some slight pinching sensation during your session. Every person has a different pain tolerance and certain areas of the body tend to be more sensitive. However, the Gentle Max Pro laser minimizes any discomfort by applying cooling cryogen before and after every laser pulse.',
    img: img_faq_2,
    webp: img_faq_2_webp,
    id: 1,
  },
  {
    question: 'Who is a candidate for laser hair removal?',
    answer:
      'At Santé Laser we treat all skin types. People with dark coarse hair are best candidates for laser hair removal. Gray, light, or fine hair are not treatable with laser. With the Gentle Max Pro, you can achieve up to 90% hair reduction. Results may vary based on your family background, hormonal tone, skin complexion, and hair color.',
    img: img_faq_3,
    webp: img_faq_3_webp,
    id: 2,
  },
  {
    question: 'How many sessions do I need?',
    answer:
      'Most people require at least 9 sessions for optimal results. Some areas may require aditional sessions to achieve maximum hair reduction. It is also important to get the treatments done at regular time intervals suggested by your esthetician. Rest period in-between sessions allows the laser to catch any inactive hair from the previous sessions. You will notice 10%-15% hair reduction after each treatmentt, and most individuals see up to 90% in overall hair reduction after nine treatments.',
    img: img_faq_4,
    webp: img_faq_4_webp,
    id: 3,
  },
  {
    question: 'Before Treatment',
    answer:
      'No tanning (including tanning beds and tan sprays) for 3 weeks. No tweezing, waxing, bleaching, or epilating for 4-6 weeks. Area of treatment should be shaved the day before. No make-up, creams, or perfume. No antibiotics for 2 week. (Must disclose all medication used prior to being treated).',
    img: img_faq_5,
    webp: img_faq_5_webp,
    id: 4,
  },
  {
    question: 'After Treatment',
    answer:
      'Avoid deodorant and make-up for 24 hours if face and under arms were treated. Avoid hot tubs, exercise, and other activities that raise your body temperature for 24 hours. Avoid prolonged sun exposure for 2 weeks. Use sunscreen. No antibiotics for 2 week.',
    img: img_faq_6,
    webp: img_faq_6_webp,
    id: 5,
  },
];

export const reviewsData = [
  {
    name: 'Dr.Lee',
    review: `I have been using the Gentle Max Pro by Candela for years. My patients are thrilled with the results, and I highly recommend it. The process is safe, effective, and pain free.`,
  },
  {
    name: 'Dr.Smith',
    review: `The Gentle Max Pro is a dual wavelength laser (both 755 Alexandrite and 1064 Nd : Yag). I have used different types of lasers over my career (IPL, Diode, and many others) and in my opinion, the Gentle Max Pro is the best for all skin tones, Alexandrite for light skin and Nd : Yag for dark skin.`,
  },
  {
    name: 'Dr.Taylor',
    review: `The Gentle Max Pro is an industry leader for laser hair removal. Its revolutionary technology with patented cryogen cooling system provides excellent results and reduces the discomfort by emitting cooling pulses in between each laser beam.`,
  },
];

export const pricingData = [
  {
    title: 'Small',
    info: 'Sideburns • Cheeks • Lip • Chin • Areola • Happy Trail • Hands • Fingers • Feet • Toes',
    additionalInfo: '10 mins | ',
    price: '$119',
    totalPrice: '$579',
  },
  {
    title: 'Medium',
    info: 'Jawline • Neck • Underarms • Half Arms • Bikini Line • Inner Buttocks',
    additionalInfo: '20 mins | ',
    price: '$159',
    totalPrice: '$759',
  },
  {
    title: 'Large',
    info: 'Face • Chest • Stomach • Arms • Half Back • Buttocks • Half Legs • Brazilian',
    additionalInfo: '30 mins | ',
    price: '$249',
    totalPrice: '$1,299',
  },
  {
    title: 'X-Large',
    info: 'Full Back • Full Legs',
    additionalInfo: '45 mins | ',
    price: '$379',
    totalPrice: '$1,879',
  },
  {
    title: 'Full Body',
    info: 'No restriction on number of areas',
    additionalInfo: '2 hrs | ',
    price: '$949',
    totalPrice: '$4,299',
  },
];
