import React, {useState} from 'react';
import {useScroll} from '../../hooks/useScroll';
import {sectionAnim} from '../../animations';
import {Title} from '../common/Title';
import {CommonWrapper, CommonRow} from '../common/Layout';
import {
  FaqSection,
  FaqContent,
  QuestionList,
  Item,
  FaqImage,
} from './styles/FAQ';

export default function FAQ({faq}) {
  const [element, controls] = useScroll();
  const [activeIndex, setActiveIndex] = useState(0);

  const handleChange = (index) => {
    setActiveIndex(index);
  };

  return (
    <FaqSection
      id="faq"
      variants={sectionAnim}
      animate={controls}
      initial="hidden"
      ref={element}
    >
      <CommonWrapper>
        <CommonRow>
          <FaqContent>
            <Title light>FAQ</Title>
            <QuestionList>
              {faq.map(({id, question, answer}) => (
                <Item key={id} active={activeIndex === id ? true : false}>
                  <h5 onClick={() => handleChange(id)} className="question">
                    {question}
                  </h5>
                  <div
                    className={
                      activeIndex === id ? 'answer answer--active' : 'answer'
                    }
                  >
                    <p>{answer}</p>
                  </div>
                </Item>
              ))}
            </QuestionList>
          </FaqContent>
          <FaqImage key={activeIndex}>
            <picture>
              <source srcSet={faq[activeIndex].webp} type="image/webp" />
              <source srcSet={faq[activeIndex].img} type="image/jpeg" />
              <img src={faq[activeIndex].img} alt={faq[activeIndex].question} />
            </picture>
          </FaqImage>
        </CommonRow>
      </CommonWrapper>
    </FaqSection>
  );
}
