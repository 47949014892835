import styled from 'styled-components/macro';
import {motion} from 'framer-motion';
import img_hero from '../../../assets/images/img_hero.jpg';
// import img_heroWebP from '../../../assets/images/img_hero.webp';

export const HeroSection = styled.section`
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  background: url(${img_hero}) rgba(0, 0, 0, 0.5);
  /* background-image: -webkit-image-set(url(${img_hero}) 1x); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: multiply;

  @media screen and (max-width: 1024px) {
    height: 600px;
  }

  @media screen and (max-width: 540px) {
    background-position: 70%;
  }
`;

export const HeroContent = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;

  color: #fff;

  img {
    max-width: 230px;

    margin-bottom: 1rem;
  }

  h1 {
    margin-bottom: 4rem;

    @media screen and (max-width: 414px) {
      margin-bottom: 2rem;
    }
  }

  .book-now-container {
    display: flex;
    align-items: center;

    @media screen and (max-width: 414px) {
      flex-direction: column;
    }

    h5 {
      margin-right: 3rem;

      @media screen and (max-width: 414px) {
        margin: 0 0 1rem 0;
      }
    }
  }
`;
