export const fadeAnim = {
  hidden: {opacity: 0},
  show: {
    opacity: 1,
    transition: {ease: 'easeInOut', duration: 0.2},
  },
};

export const sectionAnim = {
  hidden: {opacity: 0},
  show: {
    opacity: 1,
    transition: {ease: 'easeInOut', duration: .5},
  },
};

export const titleAnim = {
  hidden: {opacity: 0},
  show: {
    opacity: 1,
    transition: {duration: 0.75, ease: 'easeInOut', delay: 0.2},
  },
};

export const logoAnim = {
  hidden: {opacity: 0},
  show: {
    opacity: 1,
    transition: {duration: 0.75, ease: 'easeInOut', delay: 0.25},
  },
};

export const bookContainerAnim = {
  hidden: {opacity: 0},
  show: {
    opacity: 1,
    transition: {duration: 0.75, ease: 'easeInOut', delay: 0.3},
  },
};
