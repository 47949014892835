import React from 'react';
import styled from 'styled-components/macro';

export function Loader() {
  return (
    <LoaderContainer>
      <span className="loader-inner" />
    </LoaderContainer>
  );
}

const LoaderContainer = styled.span`
  display: inline-block;
  width: 0.875rem;
  height: 0.875rem;
  position: relative;
  border: 2px solid #fff;
  top: 50%;
  animation: loader 2s infinite ease;

  span {
    vertical-align: top;
    display: inline-block;
    width: 100%;
    background-color: #fff;
    animation: loader-inner 2s infinite ease-in;
  }

  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }

    25% {
      transform: rotate(180deg);
    }

    50% {
      transform: rotate(180deg);
    }

    75% {
      transform: rotate(360deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes loader-inner {
    0% {
      height: 0%;
    }

    25% {
      height: 0%;
    }

    50% {
      height: 100%;
    }

    75% {
      height: 100%;
    }

    100% {
      height: 0%;
    }
  }
`;
