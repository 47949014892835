import styled, {css} from 'styled-components/macro';

export const HeaderContainer = styled.header`
  width: 100%;

  background-color: transparent;
  transition: all 0.2s ease-in-out;

  position: fixed;
  top: 0;
  z-index: 99;

  ${({background}) =>
    background &&
    css`
      background-color: #fff;
      box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.12);
      -webkit-box-shadow: 0x 8px 32px 0 rgba(0, 0, 0, 0.24);
      -moz-box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.24);
    `}
`;

export const HeaderInner = styled.div`
  padding: 1rem 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  nav {
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }
`;

export const MobileMenuButton = styled.button`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
  }
`;

export const Menu = styled.ul`
  display: flex;
  align-items: center;

  color: ${({background}) => (background ? '#000' : '#fff')};

  padding-right: 1rem;
  border-right: 1px solid ${({color}) => color};
  margin-right: 2rem;

  li {
    padding: 0.5rem;
    margin: 0 1rem;

    font-size: 0.875rem;
    font-weight: 700;

    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      opacity: 50%;
    }
  }
`;

export const Social = styled.ul`
  display: flex;
  align-items: center;

  li {
    margin: 0 0.5rem;

    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      opacity: 50%;
    }
  }
`;