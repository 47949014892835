import styled, {keyframes} from 'styled-components/macro';
import {motion} from 'framer-motion';

export const FaqSection = styled(motion.section)`
  padding: 60px 0;

  background-color: #000;
`;

export const FaqContent = styled.div`
  flex: 1;

  padding-right: 80px;

  color: #fff;

  @media screen and (max-width: 414px) {
    padding-right: 0;
  }
`;

export const QuestionList = styled.ul`
  margin-bottom: 24px;
`;

export const Item = styled.li`
  padding: 8px;

  opacity: ${({active}) => (active ? '1' : '.5')};

  h5 {
    cursor: pointer;
  }

  .answer {
    max-height: 0;
    opacity: 0;
    margin-top: 0.5rem;
    overflow: hidden;
    transition: all 0.5s ease-in-out;

    &--active {
      max-height: 200px;
      opacity: 1;
    }
  }

  p {
    color: #fff;
  }
`;

const imageAnim = keyframes`
from {
  opacity: 0;
}

to {
  opacity: 1;
}
`;

export const FaqImage = styled.div`
  flex: 1;

  animation: ${imageAnim} 0.5s linear;

  img {
    width: 100%;
  }
`;
