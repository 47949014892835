import styled from 'styled-components/macro';

export const Cards = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 0.5rem;

  @media screen and (max-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 540px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 414px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Card = styled.div`
  padding: 2rem 1rem 1rem 1rem;

  border: 1px solid #000;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 992px) {
    margin: 0.5rem 0;
  }

  @media screen and (max-width: 414px) {
    margin: 0.5rem 0;
  }

  h3 {
    margin-bottom: 1rem;
  }

  h4 {
    margin-bottom: 2rem;
  }

  h5 {
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }

  .additional-info {
    margin-bottom: 1rem;

    span {
      font-weight: 600;
    }
  }

  .info {
    font-size: 0.875rem;
    line-height: 1.5rem;

    margin-bottom: 1rem;
  }
`;
