import styled from 'styled-components/macro';
import {motion} from 'framer-motion';

export const TechSection = styled(motion.div)`
  padding: 3.75rem 0;
`;

export const TechItem = styled(motion.div)`
  padding: 30px 0;

  display: flex;
  flex-direction: ${(props) => (props.imgStart ? 'row-reverse' : 'row')};

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const TechContent = styled.div`
  width: 50%;

  padding: ${(props) => (props.imgStart ? '0 0 0 80px' : '0 80px 0 0')};

  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 768px) {
    width: 100%;

    padding: 0;
    margin-bottom: 2rem;
  }

  .additional-info {
    margin-top: 1.5rem;

    span {
      font-weight: 600;
    }
  }
`;

export const TechImage = styled.div`
  width: 50%;
  max-height: 600px;

  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;

  img {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;
