import {useState} from 'react';
import styled from 'styled-components/macro';
import {motion} from 'framer-motion';
import {
  Header,
  Hero,
  Sidebar,
  Tech,
  FAQ,
  Reviews,
  Pricing,
  Contacts,
  Footer,
} from './components';
import {techData, faqData, reviewsData, pricingData} from './data';
import {fadeAnim} from './animations';
import ReactGA from 'react-ga';

const AppContainer = styled(motion.div)``;

ReactGA.initialize('UA-198225779-1');
ReactGA.pageview(window.location.pathname + window.location.search);

export default function App() {
  const [isOpen, setIsOpen] = useState(false);

  const isOpenToggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <AppContainer variants={fadeAnim} initial="hidden" animate="show">
      <Header isOpenToggle={isOpenToggle} />
      <Sidebar isOpenToggle={isOpenToggle} isOpen={isOpen} />
      <Hero />
      <Tech tech={techData} />
      <FAQ faq={faqData} />
      <Reviews reviews={reviewsData} />
      <Pricing pricing={pricingData} />
      <Contacts />
      <Footer />
    </AppContainer>
  );
}
