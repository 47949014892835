import styled from 'styled-components/macro';
import {motion} from 'framer-motion';

export const CommonSection = styled(motion.section)`
  padding: 60px 0;
  background-color: ${({background}) => background || '#fff'};
`;

export const CommonWrapper = styled.div`
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;
`;

export const CommonRow = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.imgStart ? 'row-reverse' : 'row')};

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
