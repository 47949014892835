import React, {useEffect} from 'react';
import {Container, Content} from './styles/Popup';

export function Popup() {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <Container>
      <Content>
        <h4>Thank you for contacting us!</h4>
        <p>We will get back to you shortly</p>
      </Content>
    </Container>
  );
}
