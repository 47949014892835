import styled from 'styled-components/macro';

export const SidebarContainer = styled.aside`
  width: 100%;
  height: 100%;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;

  background: #0d0d0d;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: 0.3s ease-in-out;
  opacity: ${(isOpen) => (isOpen.isOpen ? '100%' : '0')};
  top: ${(isOpen) => (isOpen.isOpen ? '0' : '-100%')};
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 24px;
  right: 16px;
  z-index: 1000;
`;

export const Menu = styled.ul`
  color: #fff;

  display: flex;
  flex-direction: column;
  align-items: center;

  li {
    height: 4rem;
    color: #fff;
    font-size: 1.5rem;
    font-weight: 700;

    text-align: center;
    text-decoration: none;

    transition: 0.2s ease-in-out;

    cursor: pointer;

    &:hover {
      opacity: 50%;
      transition: 0.2s ease-in-out;
    }
  }
`;

export const Social = styled.ul`
  display: flex;

  li {
    width: 48px;
    height: 48px;

    margin: 0 24px;

    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      opacity: 50%;
      transition: 0.2s ease-in-out;
    }
  }
`;
