import React, {useState} from 'react';
import {useScroll} from '../../hooks/useScroll';
import {sectionAnim} from '../../animations';
import {Title} from '../common/Title';
import {CommonSection, CommonWrapper} from '../common/Layout';
import ic_quote from '../../assets/icons/ic_quote.svg';
import {ReviewsContent, Slider} from './styles/Reviews';

export default function Reviews({reviews}) {
  const [element, controls] = useScroll();
  const [activeIndex, setActiveIndex] = useState(0);

  const handleChange = (index) => {
    setActiveIndex(index);
  };

  return (
    <CommonSection
      background="#F2F2F2"
      id="reviews"
      variants={sectionAnim}
      animate={controls}
      initial="hidden"
      ref={element}
    >
      <CommonWrapper>
        <Title>Reviews</Title>
        <ReviewsContent>
          <img src={ic_quote} alt="quote" />
          <Slider>
            <div className="review-container">
              {reviews.map(({review}, index) => (
                <p
                  key={index}
                  className={
                    activeIndex === index ? 'review review--active' : 'review'
                  }
                >
                  {review}
                </p>
              ))}
            </div>
            <ul className="names-container">
              {reviews.map(({name}, index) => (
                <li onClick={() => handleChange(index)} key={index}>
                  <p
                    className={
                      activeIndex === index ? 'name name--active' : 'name'
                    }
                  >
                    {name}
                  </p>
                </li>
              ))}
            </ul>
          </Slider>
        </ReviewsContent>
      </CommonWrapper>
    </CommonSection>
  );
}
